<template>
  <b-container class="" style="opacity: 0; position: absolute; z-index: -1; top: -100%">
    <b-row id="print-me" class="justify-content-center pt-2" style="background-color: #f6f7fa">
      <b-col id="contentPrintA5Delivery" ref="contentPrintA5Delivery" cols="12" lg="12">
        <b-container class="h-100 bg-white" style="letter-spacing: 1px">
          <div class="pt-5 px-lg-3 px-2 pb-3">
            <div class="d-flex mb-4 align-items-center" style="gap: 8px">
              <b-img v-if="imageLogo" :src="imageLogo" alt="logo" style="width: 100px" />
              <div class="d-flex flex-column" style="gap: 2px">
                <h6 class="size16 mb-0 text-black">Surat Jalan</h6>
                <h6 class="size16 mb-0 fw-bold-500 text-black" v-if="result.branch">
                  {{ result.branch.name || "-" }}
                </h6>
                <h6 class="size16 mb-0 fw-bold-500 text-black" v-if="result.branch">
                  {{ result.branch.address || "-" }}
                  {{ (result.branch && result.branch.phone) || "-" }}
                </h6>
              </div>
            </div>
            <b-row class="mt-2">
              <b-col cols="12">
                <table>
                  <tbody>
                    <tr>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          No. Invoice
                        </h6>
                      </td>
                      <td class="pl-0 pr-3" style="width: 21rem">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          : {{ (result && result.invoice_number) || "-" }}
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          Tanggal
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          : {{ (result && result.date) || "-" }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          No. PO SO
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          : {{ (result && result.po_so_number) || "-" }}
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          Pelanggan
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          :
                          {{ (result.customer && result.customer.name) || "-" }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          No. Surat Jalan
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          : {{ deliveryNumbers.join(", ") || "-" }}
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          Penerima
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          : {{ (result && result.receiver_name) || "-" }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          Sales
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          : {{ (result.seller && result.seller.name) || "-" }}
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">Telp</h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          :
                          {{ (result && result.receiver_phone_number) || "-" }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td class="pl-0 pr-3 d-flex">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          Kasir
                        </h6>
                      </td>
                      <td class="pl-0 pr-3" style="vertical-align: top">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          :
                          {{ (result.operator && result.operator.name) || "-" }}
                        </h6>
                      </td>
                      <td class="pl-0 pr-3 pb-1 d-flex">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          Alamat Penerima
                        </h6>
                      </td>
                      <td class="pl-0 pr-3 pb-1" style="max-width: 300px">
                        <h6 class="size16 mb-0 fw-bold-500 text-black">
                          : {{ (result && result.receiver_address) || "-" }}
                        </h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row class="mt-1 mb-3">
              <b-col lg="12">
                <table class="table table-hover">
                  <thead>
                    <tr class="border-bottom" style="
                        border-bottom: 1px solid #000 !important;
                        border-top: 1px solid #000 !important;
                      ">
                      <th scope="col" class="text-left" style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                          padding: 0;
                          width: 2rem;
                        ">
                        No
                      </th>
                      <th scope="col" class="text-left" style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                          padding: 0;
                        ">
                        Item
                      </th>
                      <th scope="col" class="text-left" style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                          padding: 0;
                        ">
                        Qty
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in dataDelivery" :key="index">
                      <td class="border-0 items__list">
                        {{ index + 1 }}
                      </td>
                      <td class="border-0 items__list">
                        {{ item.name }} <span v-if="item.bundle">({{ item.bundle && item.bundle.name }})</span>
                      </td>
                      <td class="border-0 items__list text-left" style="width: 22rem">
                        {{ item.qty.toString().replace(".", ",") }}
                        {{ item.unit }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr style="border-bottom: 1px solid #000 !important" />
                <h6 class="size16 text-black">Note:</h6>
                <div class="mb-2" v-html="memo" />
              </b-col>
            </b-row>
            <b-row align-h="end">
              <b-col cols="6">
                <h6 class="size16 text-black size16">TTD Penerima</h6>
              </b-col>
              <b-col cols="6">
                <h6 class="size16 text-black size16 ml-5 text-center">
                  Hormat Kami
                </h6>
              </b-col>
              <!-- <b-col lg="6 mt-5">
                <h6 class="size16 text-black size16">Note:</h6>
                <h6
       size16            class="text-black size16 font-italic"
                  v-if="result.display_vat === 1"
                >
                  *Harga barang sudah termasuk PPn 11%
                </h6>
                <h6 class="size16 text-black size16 font-italic">
                  {{ result.invoice_notes }}
                </h6>
                <h6
       size16            v-if="result.returs_id && result.returs_id.length > 0"
                  class="size16 text-black mt-4"
                >
                  {{ result.returs_id.join(", ") }}
                </h6>
              </b-col> -->
            </b-row>
          </div>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BImg } from "bootstrap-vue";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BImg,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    result: {
      type: Object,
    },
    merchant: {
      type: Object,
    },
    dataDelivery: {
      type: Array,
    },
    deliveryNumbers: {
      type: Array,
    },
    memo: {
      type: String,
    },
  },
  data() {
    return {
      moment,
      imageLogo: null
      // deliveries: [],
    };
  },
  watch: {
    // dataDelivery: {
    //   handler(value) {
    //     this.deliveries = value;
    //   },
    //   deep: true,
    // },
    result: {
      handler(value) {
        if (value) {
          const isValid = this.hasNoImageExtension(value?.branch?.photo_url)
          this.imageLogo = isValid ? value.branch?.photo_url : ''
        }
      },
      deep: true
    }
  },
  mounted() {
    const isValid = this.hasNoImageExtension(this.result?.branch?.photo_url)
      this.imageLogo = isValid ? this.result?.branch?.photo_url : ''
  },
  methods: {
    hasNoImageExtension(url) {
      const validExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
      return validExtensions.test(url);
    },
    sanitizeHTML(html) {
      return html?.replace(/<\/?p>/g, "");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";

.opacity-0 {
  opacity: 0 !important;
}

.table thead tr th {
  font-weight: 800;
  color: #2b324f;
  font-size: 16px;
}

.table tbody tr td {
  font-weight: 500;
  color: #2b324f;
  font-size: 16px;
}

.bg-wave {
  background-image: url("../assets/images/wave-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

button {
  &.bg-white {
    background-color: #ffffff !important;
    border: 1px solid $light--3 !important;
    color: $dark !important;
  }
}

label {
  font-size: 14px;
  color: $dark;
  font-weight: 600;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

.custom__input {
  height: 52px;
  background: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
}

.vs__dropdown-toggle {
  height: 52px;
  background-color: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
}

// #contentPrintA5 {
//     page-break-before: always;
// }</style>
