<template>
  <b-container class="mt-5 pt-5" style="opacity: 0; position: absolute; z-index: -1; top: -100%">
    <b-row id="print-me" class="justify-content-center pt-2" style="background-color: #f6f7fa">
      <b-col id="contentPrintA5" ref="contentPrintA5" cols="12" lg="10">
        <b-container class="h-100 bg-white" style="letter-spacing: 1px">
          <div class="pt-5 px-lg-3 px-2 pb-3">
            <div class="d-flex mb-4 align-items-center" style="gap: 8px">
              <b-img v-if="imageLogo" :src="imageLogo" alt="logo" style="width: 100px" />

              <div class="d-flex flex-column" style="gap: 2px">
                <h6 class="size13 text-black">Nota</h6>
                <h6 class="size13 fw-bold-500 text-black" v-if="result.branch">
                  {{ result.branch.name || "-" }}
                </h6>
                <h6 class="size13 fw-bold-500 text-black" v-if="result.branch">
                  {{ result.branch.address || "-" }}
                  {{ (result.branch && result.branch.phone) || "-" }}
                </h6>
              </div>
            </div>
            <b-row class="mt-2">
              <b-col cols="12">
                <table>
                  <tbody>
                    <tr>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          No. Invoice
                        </h6>
                      </td>
                      <td class="pl-0 pr-3" style="width: 21rem">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          : {{ (result && result.invoice_number) || "-" }}
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          Tanggal
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          : {{ (result && result.date) || "-" }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          No. PO SO
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          : {{ (result && result.po_so_number) || "-" }}
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          Pelanggan
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          :
                          {{ (result.customer && result.customer.name) || "-" }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          No. Surat Jalan
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          : {{ deliveryNumbers.join(", ") || "-" }}
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          Penerima
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          : {{ (result && result.receiver_name) || "-" }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          Sales
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          : {{ (result.seller && result.seller.name) || "-" }}
                        </h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">Telp</h6>
                      </td>
                      <td class="pl-0 pr-3">
                        <h6 class="size13 fw-bold-500 text-black mb-0">
                          :
                          {{ (result && result.receiver_phone_number) || "-" }}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td class="pl-0 pr-3 d-flex">
                        <h6 class="size13 mb-0 fw-bold-500 text-black">
                          Kasir
                        </h6>
                      </td>
                      <td class="pl-0 pr-3" style="vertical-align: top">
                        <h6 class="size13 mb-0 fw-bold-500 text-black">
                          :
                          {{ (result.operator && result.operator.name) || "-" }}
                        </h6>
                      </td>
                      <td class="pl-0 pr-3 pb-1 d-flex">
                        <h6 class="size13 mb-0 fw-bold-500 text-black">
                          Alamat Penerima
                        </h6>
                      </td>
                      <td class="pl-0 pr-3 pb-1" style="max-width: 300px">
                        <h6 class="size13 mb-0 fw-bold-500 text-black">
                          : {{ (result && result.receiver_address) || "-" }}
                        </h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row class="mt-1 ml-0">
              <b-col lg="12" class="pl-0">
                <table class="table table-hover mb-0">
                  <thead>
                    <tr class="border-bottom" style="
                        border-bottom: 1px solid #000 !important;
                        border-top: 1px solid #000 !important;
                      ">
                      <th scope="col" class="text-left size13" style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                          width: 16rem;
                        ">
                        Item
                      </th>
                      <th scope="col" class="text-right size13" style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                        ">
                        Qty
                      </th>
                      <th scope="col" class="text-right size13" style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                        ">
                        Harga Satuan
                      </th>
                      <th scope="col" class="text-right size13" style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                        ">
                        Diskon Satuan
                      </th>
                      <th scope="col" class="text-right size13" style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                        ">
                        Harga
                      </th>
                      <th scope="col" class="text-right size13" style="
                          border-bottom: 1px solid #000 !important;
                          border-top: 1px solid #000 !important;
                        ">
                        Jumlah
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in result.items" :key="index">
                      <td class="border-0 items__list-inkjet size13">
                        {{ item.name }} <span v-if="item.bundle">({{ item.bundle && item.bundle.name }})</span>
                      </td>
                      <td class="border-0 items__list-inkjet size13 text-right">
                        {{ item.qty.toString().replace(".", ",") }}
                        {{ item.unit }}
                      </td>
                      <td class="border-0 items__list-inkjet size13 text-right" v-if="!item.bundle">
                        {{ item.sub_price | formatInvoice }}
                      </td>
                      <td class="border-0 items__list-inkjet size13 text-right" v-if="!item.bundle">
                        {{ item.discount_per_item | formatInvoice }}
                      </td>
                      <td class="border-0 items__list-inkjet size13 text-right" v-if="!item.bundle">
                        {{ item.price | formatInvoice }}
                      </td>
                      <td class="border-0 items__list-inkjet size13 text-right" v-if="!item.bundle">
                        {{ item.total | formatInvoice }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr class="my-1" style="border-top: 1px solid #000" />
                <b-row>
                  <b-col cols="6">
                    <b-row>
                      <b-col cols="6">
                        <h6 class="size13 text-black">TTD Penerima</h6>
                      </b-col>
                      <b-col cols="6">
                        <h6 class="size13 text-black pl-2 text-center">
                          Hormat Kami
                        </h6>
                      </b-col>
                    </b-row>
                    <b-col lg="12" style="margin-top: 60px">
                      <h6 class="size13 text-black mb-0">Note:</h6>
                      <h6 class="size13 text-black mb-0 font-italic" v-if="result.display_vat === 1">
                        *Harga barang sudah termasuk PPn 11%
                      </h6>
                      <h6 class="size13 text-black mb-0 font-italic">
                        {{ result.invoice_notes }}
                      </h6>
                      <h6 v-if="result.returs_id && result.returs_id.length > 0" class="text-black mt-4">
                        {{ result.returs_id.join(", ") }}
                      </h6>
                      <h6 v-if="result.deleted_by" class="size10 fw-bold-700 text-danger">
                        Dibatalkan oleh : {{ result.deleted_by.name }} /
                        {{ result.deleted_at }}
                      </h6>
                    </b-col>
                  </b-col>
                  <b-col cols="6">
                    <div class="d-flex justify-content-between">
                      <h6 class="size13 text-black mb-0 fw-bold-700">
                        Sub Total
                      </h6>
                      <h6 class="size13 text-black mb-0 fw-bold-700">
                        {{ result.subtotal | formatAmount }}
                      </h6>
                    </div>
                    <div v-if="result.discount_value > 0" class="d-flex justify-content-between">
                      <h6 class="size13 text-black mb-0">Diskon Tambahan</h6>
                      <h6 v-if="result.discount_type == 'fixed'" class="size13 text-black mb-0">
                        - {{ result.discount_value | formatAmount }}
                      </h6>
                      <h6 v-if="result.discount_type == 'percent'" class="size13 text-black mb-0">
                        - {{ result.discount_value }}%
                      </h6>
                    </div>
                    <div v-if="result.additional_fee_total > 0" class="d-flex justify-content-between">
                      <h6 class="size13 text-black mb-0 fw-bold-700">
                        Total Biaya Tambahan
                      </h6>
                      <h6 class="size13 text-black mb-0 fw-bold-700">
                        {{ result.additional_fee_total | formatAmount }}
                      </h6>
                    </div>
                    <info-additional-cost :result="result" />
                    <!-- Split Payment -->
                    <hr v-if="result.payments && result.payments.length > 1" class="mb-25"
                      style="border-top: 1px solid #000" />
                    <h6 class="size13 text-black size13 fw-bold-700 mb-0"
                      v-if="result.payments && result.payments.length > 1">
                      Pembayaran Terbagi
                    </h6>
                    <div class="d-flex justify-content-between" v-for="(payment, indexPayment) in result.payments"
                      :key="indexPayment">
                      <h6 class="size13 text-black size13 mb-0 text-capitalize mb-0">
                        {{ paymentMethods(payment.payment_method) }}
                        {{
                          payment.payment_method == "transfer"
                            ? " ke " + payment.bank_account_payment.bank.name
                            : ""
                        }}
                      </h6>
                      <h6 class="size13 text-black size13 mb-0">
                        {{ parseFloat(payment.amount) | formatAmount }}
                      </h6>
                    </div>
                    <hr v-if="result.payments && result.payments.length > 1" class="mt-0 mb-25"
                      style="border-top: 1px solid #000" />
                    <!-- Total Split Payment -->
                    <div v-if="result.payments && result.payments.length > 1" class="d-flex justify-content-between"
                      style="margin-bottom: 0.5rem">
                      <h6 class="size13 text-black size13 mb-0">
                        Total Pembayaran
                      </h6>
                      <h6 class="size13 text-black mb-0">
                        {{
                          sumSplitPayment(result.payments) | formatAmountInvoice
                        }}
                      </h6>
                    </div>
                    <div v-if="result.discount_total > 0" class="d-flex justify-content-between">
                      <h6 class="text-black size13 mb-0 fw-bold-700">
                        Total Diskon
                      </h6>
                      <h6 class="text-black size13 mb-0 fw-bold-700">
                        {{ result.discount_total | formatAmount }}
                      </h6>
                    </div>
                    <div class="d-flex justify-content-between">
                      <h6 class="text-black size13 mb-0 fw-bold-700">
                        Grand Total
                      </h6>
                      <h6 class="text-black size13 mb-0 fw-bold-700">
                        {{ result.total | formatAmount }}
                      </h6>
                    </div>
                    <!-- <div
                      class="d-flex justify-content-between"
                    >
                      <h6 class="text-black mb-0">
                        {{ paymentMethods(result.payment_method) }}
                      </h6>
                      <h6 class="text-black mb-0">
                        {{ result.cash_total_payment | formatAmount }}
                      </h6>
                    </div> -->
                    <div v-if="
                      result &&
                      result.payment_method !== 'piutang' &&
                      result.piutang &&
                      result.piutang.amount > 0
                    " class="d-flex justify-content-between">
                      <h6 class="text-black size13 mb-0 text-capitalize">
                        Piutang
                      </h6>
                      <h6 class="text-black size13 mb-0">
                        {{
                          result.piutang && result.piutang.amount | formatAmount
                        }}
                      </h6>
                    </div>
                    <div v-if="result.payment_method !== 'piutang'" class="d-flex justify-content-between">
                      <h6 class="text-black size13 mb-0" v-if="result.payment_method === 'wallet'">
                        Sisa Deposit
                      </h6>
                      <h6 class="text-black size13 mb-0" v-if="result.payment_method === 'wallet'">
                        {{
                          result.payment_customer &&
                          result.payment_customer.wallet_balance
                          | formatAmountInvoice
                        }}
                      </h6>
                      <div class="d-flex justify-content-between w-100 mb-25" v-if="
                        (result.payment_method !== 'wallet' &&
                          result.payments &&
                          !result.payments.length > 1) ||
                        (result.returs_id && result.returs_id.length)
                      ">
                        <h6 class="text-black size13 mb-0">Kembali</h6>
                        <h6 class="text-black size13 mb-0">
                          {{ result.cash_change | formatAmountInvoice }}
                        </h6>
                      </div>
                    </div>
                    <div v-if="result.discount_total > 0" class="d-flex justify-content-between">
                      <h6 class="text-black size13 mb-0">Anda Hemat</h6>
                      <h6 class="text-black size13 mb-0">
                        {{ result.discount_total | formatAmount }}
                      </h6>
                    </div>
                  </b-col>
                </b-row>
                <!-- <b-row class="mt-3">
                  <b-col cols="6">
                    <h6 class="text-black">TTD Penerima</h6>
                  </b-col>
                  <b-col cols="6">
                    <h6 class="text-black pl-2 text-center">
                      Hormat Kami
                    </h6>
                  </b-col>
                </b-row> -->
              </b-col>

              <!-- <b-col lg="12" class="pl-0 mb-0">
                <b-row>
                  <b-col lg="12" style="margin-top: 113px">
                    <h6 class="text-black mb-0">Note:</h6>
                    <h6
                      class="text-black mb-0 font-italic"
                      v-if="result.display_vat === 1"
                    >
                      *Harga barang sudah termasuk PPn 11%
                    </h6>
                    <h6 class="text-black mb-0 font-italic">
                      {{ result.invoice_notes }}
                    </h6>
                    <h6
                      v-if="result.returs_id && result.returs_id.length > 0"
                      class= text-black mt-4 mb-0"
                    >
                      {{ result.returs_id.join(", ") }}
                    </h6>
                  </b-col>
                </b-row>
              </b-col> -->
            </b-row>
          </div>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BImg } from "bootstrap-vue";
import moment from "moment";
import InfoAdditionalCost from "./InfoAdditionalCost.vue";
const { hostname, protocol, port } = window.location;
export default {
  components: {
    BRow,
    BCol,
    BImg,
    BContainer,
    InfoAdditionalCost,
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    result: {
      type: Object,
    },
    merchant: {
      type: Object,
    },
    deliveryNumbers: {
      type: Array,
    },
  },
  data() {
    return {
      moment,
      imageLogo: null
    };
  },
  computed: {
    totalRetur() {
      return this.result?.returs?.reduce((total, item) => total + item.return_value, 0);
    },
    totalDiscountRetur() {
      return this.result?.returs?.reduce((total, item) => total + item.discount, 0);
    }
  },
  watch: {
    result: {
      handler(value) {
        if (value) {
          const isValid = this.hasNoImageExtension(value?.branch?.photo_url)
          this.imageLogo = isValid ? value.branch?.photo_url : `${protocol}//${hostname}${port !== "" ? `:${port}` : ""}/toqoo-green.svg`
        }
      },
      deep: true
    }
  },
  mounted() {
    const isValid = this.hasNoImageExtension(this.result?.branch?.photo_url)
    this.imageLogo = isValid ? this.result?.branch?.photo_url : ''
  },
  methods: {
    hasNoImageExtension(url) {
      const validExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
      return validExtensions.test(url);
    },
    sumSplitPayment(payments) {
      let total = 0;
      for (let index = 0; index < payments.length; index++) {
        const element = payments[index];
        total += parseFloat(element.amount);
      }
      return total;
    },
    paymentMethods(method) {
      const paymentMapping = {
        cash: "Tunai",
        card: "Debit/Kredit",
        transfer: "Transfer",
        giro: "Giro",
        wallet: "Deposit",
        piutang: "Piutang",
      };

      return paymentMapping[method] || "-";
    },
    sanitizeHTML(html) {
      return html?.replace(/<\/?p>/g, "");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";

.table thead tr th {
  font-weight: 800;
  color: #2b324f;
  font-size: 16px;
}

.table tbody tr td {
  font-weight: 500;
  color: #2b324f;
  font-size: 16px;
}

.bg-wave {
  background-image: url("../assets/images/wave-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

button {
  &.bg-white {
    background-color: #ffffff !important;
    border: 1px solid $light--3 !important;
    color: $dark !important;
  }
}

label {
  font-size: 14px;
  color: $dark;
  font-weight: 600;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

.custom__input {
  height: 52px;
  background: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
}

.vs__dropdown-toggle {
  height: 52px;
  background-color: #eff1f5;
  border: 1px solid #e4e5ec;
  border-radius: 8px;
}
</style>
