<template>
  <div>
    <div style="background-color: #f6f7fa; height: 100dvh;overflow-y: hidden;">
      <b-container fluid class="px-0 py-0">
        <header
          class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line px-2 py-1 bg-white">
          <b-row class="justify-content-between">
            <b-col class="align-self-center" lg="4" md="4">
              <div v-if="$route.query.type == 'show_transaction'" style="cursor: pointer"
                @click="$router.push({ name: 'transaction.index' })">
                <feather-icon size="24" class="text-dark fw-bold-800" icon="ArrowLeftIcon" />
                <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
              </div>
              <h1 v-else class="text-dark text-darken-7 fw-bold-700 size16 mb-0">
                {{
                  $route.query.q == "payment_report"
                    ? "Berita Acara Pembayaran"
                    : "Cetak Struk Penjualan"
                }}
              </h1>
            </b-col>
            <b-col class="align-self-center text-right" lg="8" md="8">
              <div v-if="$route.query.type == 'show_transaction'" class="d-flex justify-content-end">
                <b-button class="py-1 bg-white border-8 mr-1">
                  <b-img class="mr-1" :src="require('@/assets/images/icons/print.svg')" alt="icon-cetak"
                    @click="printA5" />
                  <span>Cetak</span>
                </b-button>
                <b-button class="px-2 py-1 pt-1 border-8" @click="
                  $router.push({
                    name: 'transaction.show',
                    params: { id: $route.params.id },
                  })
                  ">
                  Edit Transaksi
                </b-button>
              </div>
              <div v-else class="d-flex justify-content-end align-items-center" style="gap: 16px">
                <div class="dropdown" v-if="$route.query.q !== 'payment_report'">
                  <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                    aria-expanded="false">
                    Cetak di Dot Matrix
                  </a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#" @click="printThermalDotMatrix">Cetak Struk 80mm</a>
                    <a class="dropdown-item" href="#" @click="printA5">Cetak Nota A5</a>
                    <a class="dropdown-item" href="#" @click="printDeliveryNumbers">Cetak Surat Jalan</a>
                  </div>
                </div>
                <div class="dropdown" v-if="$route.query.q !== 'payment_report'">
                  <a class="btn btn-secondary dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                    aria-expanded="false">
                    Cetak di Thermal
                  </a>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="#" @click="printThermalPC">Cetak Struk di PC</a>
                    <a class="dropdown-item" href="#" @click="printInvoice">Cetak Di Android</a>
                    <a class="dropdown-item" href="#" @click="printInvoiceSmall">Cetak Di Android 58mm</a>
                  </div>
                </div>
                <b-button class="px-2 py-1 pt-1 border-8" @click="printA5">
                  Cetak di Inkjet
                </b-button>
                <b-button class="bg-white px-2 py-1 pt-1 border-8" @click="redirect">
                  Selesai
                </b-button>
              </div>
            </b-col>
          </b-row>
        </header>
      </b-container>
      <div v-if="!isLoading">
        <PaymentReport v-if="$route.query.q == 'payment_report'" :result="result" class="mt-5 pt-5" />
        <InvoiceComponent v-else :result="result" :merchant="merchant" :delivery-numbers="deliveryNumbers" />
        <InvoiceA5 :result="result" :delivery-numbers="deliveryNumbers" :merchant="merchant" />
      </div>
      <div v-else class="d-flex justify-content-center align-items-center" style="height: 100dvh;gap: 8px">
        <b-spinner label="Loading..." />
        <h1 class="mb-0">Please wait...</h1>
      </div>
      <!-- <Riwayat /> -->
      <b-modal id="modal-delivery-numbers" hide-header hide-footer centered title="Vertically Centered" ok-only
        ok-title="Accept">
        <div class="p-2 d-flex justify-content-between align-items-center">
          <h4 class="text-dark font-bold-700 size16 mb-0">
            Pilih Barang Untuk Surat Jalan
          </h4>
          <div role="button" @click="$bvModal.hide('modal-delivery-numbers')">
            <feather-icon size="24" class="text-dark font-weight-bolder" icon="XIcon" />
          </div>
        </div>
        <b-container class="">
          <b-row class="p-2">
            <b-col cols="12">
              <table class="table table-hover">
                <thead>
                  <tr class="border-bottom">
                    <th scope="col" class="d-flex text-left">
                      <b-form-checkbox v-model="allSelected" @click="selectAll" />
                      Item
                    </th>
                    <th scope="col" class="text-center">Qty</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in result.items" :key="index" class="border-bottom">
                    <td class="border-0 d-flex">
                      <b-form-checkbox v-model="deliveryIds" :value="item.uuid" @change="select" />
                      {{ item.name || "-" }}
                    </td>
                    <td class="border-0 text-center">
                      {{ item.qty }} {{ item.unit }}
                    </td>
                  </tr>
                </tbody>
              </table>
  
              <!-- Memo -->
              <div class="custom__form--input mb-2">
                <label class="text-dark" for="memo">Memo</label>
                <quil-editor :payload="memo" @contentText="getContentText" />
                <!-- <b-form-textarea
                  id="memo"
                  placeholder="Memo"
                  v-model="dataDelivery.memo"
                  rows="3"
                  class="custom__textarea"
                  maxlength="180"
                /> -->
                <!-- <small>{{ dataDelivery.memo && dataDelivery.memo.length || 0 }}/180 </small> -->
              </div>
  
              <div class="text-right">
                <b-button variant="dark" :disabled="deliveryIds.length == 0" @click="printInvoiceDelivery">
                  Cetak
                </b-button>
              </div>
              <InvoiceA5Delivery :result="result" :merchant="merchant" :data-delivery="dataDelivery"
                :delivery-numbers="deliveryNumbersIds" :memo="memo" />
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
    </div> 
  </div>
</template>

<script>
import printJS from "print-js";
import {
  BContainer,
  BButton,
  BRow,
  BCol,
  BSpinner,
  BFormCheckbox,
  BImg,
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import InvoiceComponent from "@/components/InvoiceComponent.vue";
import InvoiceA5 from "@/components/InvoiceA5.vue";
import InvoiceA5Delivery from "@/components/InvoiceA5Delivery.vue";
import PaymentReport from "@/components/PaymentReport.vue";
import Riwayat from "@/components/MasterProduct/Part/Riwayat.vue";
import QuilEditor from "@/components/QuilEditor.vue";
// import bootstrapCss from '@/assets/css/bootstrap.css'
import ThermalPrintTemplate from "@/components/ThermalPrintTemplate.vue";

const { hostname, protocol, port } = window.location;

import Vue from "vue";

export default {
  title() {
    return "Invoice";
  },
  components: {
    BButton,
    BRow,
    BCol,
    BContainer,
    InvoiceComponent,
    PaymentReport,
    InvoiceA5,
    Riwayat,
    BFormCheckbox,
    InvoiceA5Delivery,
    BImg,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    QuilEditor,
    BSpinner,
  },
  props: {
    typeInvoice: {
      type: String,
      default: "normal",
    },
  },
  data() {
    return {
      result: {},
      merchant: {},
      selected: [],
      allSelected: false,
      deliveryIds: [],
      dataDelivery: [],
      deliveryNumbers: [],
      deliveryNumbersIds: [],
      memo: "",
      isLoading: true,
    };
  },
  watch: {
    deliveryIds(value) {
      this.deliveryNumbersIds = [];
      const result = this.result.items.filter((el) => value.includes(el.uuid));
      this.dataDelivery = result;
      // this.deliveryNumbersIds = result.delivery_numbers
      result.forEach((element) => {
        if (element.delivery_numbers.length) {
          this.deliveryNumbersIds.push(
            ...element.delivery_numbers.map((e) => e.delivery_number_note)
          );
        }
      });
    },
    allSelected() {
      this.selectAll();
    },
  },
  mounted() {
    this.getData();
    this.getMerchant();
  },
  methods: {
    sanitizeHtml(html) {
      // Remove any potentially problematic elements or attributes
      return html
        .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "")
        .replace(/<iframe\b[^<]*(?:(?!<\/iframe>)<[^<]*)*<\/iframe>/gi, "")
        .replace(/ on\w+="[^"]*"/g, "");
    },
    getContentText(val) {
      this.memo = val;
    },
    selectAll() {
      this.deliveryIds = [];
      this.dataDelivery = [];

      if (this.allSelected) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in this.result.items) {
          if (Object.hasOwnProperty.call(this.result.items, key)) {
            const element = this.result.items[key];
            this.deliveryIds.push(element.uuid);
            this.dataDelivery.push(element);
          }
        }
      }
    },
    select() {
      this.allSelected = false;
      return true;
    },
    printDeliveryNumbers() {
      if (this.$route.query.q !== "payment_report") {
        this.$bvModal.show("modal-delivery-numbers");
      }
    },
    async printA5() {
      await this.$htmlToPaper("contentPrintA5");
    },
    async printInvoiceDelivery() {
      if (this.$route.query.q !== "payment_report") {
        // Pass the element id here
        await this.$htmlToPaper("contentPrintA5Delivery");
      }
    },
    async printThermalPC() {
      await printJS({
        printable: "thermal-print",
        type: "html",
        css: [
          `${protocol}//${hostname}${port !== "" ? `:${port}` : ""
          }/bootstrap.css`,
          `${protocol}//${hostname}${port !== "" ? `:${port}` : ""
          }/portrait.css`,
        ],
        scanStyles: false,
      });
    },
    async printThermalDotMatrix() {
      await printJS({
        printable: "thermal-print-matrix",
        type: "html",
        css: [
          `${protocol}//${hostname}${port !== "" ? `:${port}` : ""
          }/bootstrap.css`,
          `${protocol}//${hostname}${port !== "" ? `:${port}` : ""
          }/dotmatrix.css`,
        ],
        scanStyles: false,
      });
    },
    printInvoice() {
      if (this.$route.query.q !== "payment_report") {
        const { hostname, protocol, port } = window.location;

        // Create an instance of the ThermalPrintTemplate
        const ThermalPrintComponent = Vue.extend(ThermalPrintTemplate);
        const instance = new ThermalPrintComponent({
          propsData: {
            result: this.result,
            merchant: this.merchant,
          },
        });

        // Mount the component to get access to its DOM
        instance.$mount();

        // Get the HTML content
        let htmlContent = instance.$el.outerHTML;

        // Sanitize the HTML content
        htmlContent = this.sanitizeHtml(htmlContent);

        // Add necessary styles
        const styles = `
          <style>
            * { margin: 0; padding: 0; box-sizing: border-box; }
            body { font-family: "Manrope, sans-serif"; font-size: 10pt; line-height: 1.2; }
            .thermal-print { width: 100%; }
            .image-logo-placement { width: 100%; text-align: center; }
            .image-logo-android { width: 150px; height: auto; margin-bottom: 24px; }
            .product-item { margin-bottom: 4px; }
            .product-name { font-weight: 500; font-size: 10pt; }
            .product-details { display: flex; justify-content: space-between; }
            .product-details-type { width: 40%; }
            .product-details-price { width: 20%; text-align: end; }
            .summary-item { display: flex; justify-content: space-between; margin-bottom: 2px; }
            .summary-header { font-weight: bold; margin-bottom: 5px; }
            .total { font-weight: bold; font-size: 12pt; margin-top: 5px;}
            .note-header { font-weight: bold; margin-bottom: 2px; }
            .note-item { font-style: italic; }
          </style>
        `;

        // Combine styles and content
        const fullHtml = `${styles}${htmlContent}`;

        // Use the ESC/POS plugin to print
        window.location.href = `print://escpos.org/escpos/bt/print?srcTp=uri&srcObj=html&numCopies=1&src="data:text/html,${encodeURIComponent(
          fullHtml
        )}"`;

        // Destroy the instance to prevent memory leaks
        instance.$destroy();
      }
      return false;
    },

    // sanitizeHtml(html) {
    //   // Remove any potentially problematic elements or attributes
    //   return html
    //     ?.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "")
    //     ?.replace(/<iframe\b[^<]*(?:(?!<\/iframe>)<[^<]*)*<\/iframe>/gi, "")
    //     ?.replace(/ on\w+="[^"]*"/g, "");
    // },
    printInvoiceSmall() {
      if (this.$route.query.q !== "payment_report") {
        const { hostname, protocol, port } = window.location;

        // Create an instance of the ThermalPrintTemplate
        const ThermalPrintComponent = Vue.extend(ThermalPrintTemplate);
        const instance = new ThermalPrintComponent({
          propsData: {
            result: this.result,
            merchant: this.merchant,
          },
        });

        // Mount the component to get access to its DOM
        instance.$mount();

        // Get the HTML content
        let htmlContent = instance.$el.outerHTML;

        // Sanitize the HTML content
        htmlContent = this.sanitizeHtml(htmlContent);

        // Add necessary styles
        const styles = `
          <style>
            * { margin: 0; padding: 0; box-sizing: border-box; }
            body { font-family: "Manrope, sans-serif"; font-size: 10pt; line-height: 1.2; }
            .thermal-print { width: 100%; }
            .image-logo-placement { width: 100%; text-align: center; }
            .image-logo-android { width: 100px; height: auto; margin-bottom: 16px; }
            .product-item { margin-bottom: 4px; }
            .product-name { font-weight: 500; font-size: 10pt; }
            .product-details { display: flex; justify-content: space-between; }
            .product-details-type { width: 40%; }
            .product-details-price { width: 20%; text-align: end; }
            .summary-item { display: flex; justify-content: space-between; margin-bottom: 2px; }
            .summary-header { font-weight: bold; margin-bottom: 5px; }
            .total { font-weight: bold; font-size: 12pt; margin-top: 5px;}
            .note-header { font-weight: bold; margin-bottom: 2px; }
            .note-item { font-style: italic; }
          </style>
        `;

        // Combine styles and content
        const fullHtml = `${styles}${htmlContent}`;

        // Use the ESC/POS plugin to print
        window.location.href = `print://escpos.org/escpos/bt/print?srcTp=uri&srcObj=html&numCopies=1&src="data:text/html,${encodeURIComponent(
          fullHtml
        )}"`;

        // Destroy the instance to prevent memory leaks
        instance.$destroy();
      }
      return false;
    },
    redirect() {
      // if (this.$route.query.page) {
      if (this.$route.query.pembayaran) {
        this.$router.push({
          name: "payout.index",
          query: {
            pembayaran: this.$route.query.pembayaran,
            page: this.$route.query.page || 1,
            // p: this.$route.query.p,
          },
        });
      } else if (this.$route.params.q === "utang") {
        this.$router.push({ name: "debt.index" });
      } else {
        this.$router.push({ name: "transaction.index" });
        // this.$router.push({ name: 'transaction.index', query: { page: this.$route.query.page || 1 } })
      }
      // } else {
      //   window.location.href = "/";
      // }
    },
    getData() {
      this.isLoading = true
      if (this.$route.query.q === "payment_report") {
        const paymentType = {
          payment_type: this.$route.query.payment_type,
        };
        // eslint-disable-next-line no-nested-ternary
        this.$store
          .dispatch(
            `payout/${this.$route.query.type === "supplier"
              ? "getSupplier"
              : this.$route.query.type === "other"
                ? "getOthers"
                : this.$route.query.type === "utang_piutang"
                  ? "getSale"
                  : "getDetailOrder"
            }`,
            {
              uuid: this.$route.params.id,
              params: this.$route.query.payment_type ? paymentType : "",
            }
          )
          .then((result) => {
            this.result = result.data.data;
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
          }).finally(() => {
            this.isLoading = false
          });
      } else {
        this.$store
          .dispatch("order/getData", {
            uuid: `${this.$route.params.id}/detail`,
            params: "",
          })
          .then((result) => {
            this.result = result.data.data;
            result.data.data.items.forEach((element) => {
              if (element.delivery_numbers.length) {
                this.deliveryNumbers.push(
                  ...element.delivery_numbers.map((e) => e.delivery_number_note)
                );
              }
            });
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          }).finally(() => {
            this.isLoading = false
          });;
      }
    },
    getMerchant() {
      this.$store
        .dispatch("profile/getMerchant")
        .then((result) => {
          this.merchant = result.data.data;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  background-color: #f6f7fa;
}

button.bg-white {
  background-color: #ffffff !important;
  border: 1px solid;
}
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

#modal-delivery-numbers {
  .modal-dialog {

    // max-width: 603px;
    .modal-content {
      // max-width: 603px;
      background: #fcfcfc;
      border-radius: 24px !important;

      .modal-body {
        padding: 0;

        .form-group {
          label {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
          }

          input {
            background: #e7e7ed;
            border: none;
            border-radius: 8px;
            height: 52px;
            color: $dark;
            font-weight: 600;
          }
        }

        .button__group {
          display: flex;
          width: 100%;

          .button__cancel {
            background-color: #ffffff !important;
            border-color: #fff !important;
            color: $dark !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 16px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

          .button__save {
            background-color: #45b6ab !important;
            border-color: #45b6ab !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 16px 0px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }
        }
      }
    }
  }
}

.image-logo-placement_center {
  display: flex;
  justify-content: center;
  max-width: 100dvw;
}
</style>
