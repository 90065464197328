<template>
  <div class="thermal-print">
    <!-- Logo -->
    <!-- <div class="image-logo-placement">
      <img v-if="
        result.branch &&
        (result.branch.photo_url.includes('png') ||
          result.branch.photo_url.includes('jpg'))
      " :src="result.branch.photo_url" alt="logo" class="image-logo-android" />
      
    </div> -->
    <table style="width: 100%" v-if="result.branch && result.branch.photo_url">
      <tr>
        <td style="text-align: center;">
          <img :src="result.branch && result.branch.photo_url" alt="logo" class="image-logo-android" />
        </td>
      </tr>
    </table>
    <!-- Header -->
    <div class="header">
      <div v-if="result.branch" class="branch-name">
        {{ result.branch.name || "-" }}
      </div>
      <div v-if="result.branch" class="branch-address">
        {{ result.branch.address || "-" }}
      </div>
      <div v-if="result.branch" class="branch-phone">
        Tlp: {{ result.branch.phone }}
      </div>
    </div>

    <!-- Invoice Details -->
    <div class="invoice-details">
      <div>No. Invoice: {{ result.invoice_number || "-" }}</div>
      <div v-if="result.po_so_number">
        No. PO SO: {{ result.po_so_number || "-" }}
      </div>
      <div v-if="deliveryNumbers && deliveryNumbers.length">
        No. Surat Jalan: {{ deliveryNumbers.join(", ") || "-" }}
      </div>
      <div>Tanggal: {{ result.date || "-" }}</div>
      <div>Sales: {{ (result.seller && result.seller.name) || "-" }}</div>
      <div>Kasir: {{ (result.operator && result.operator.name) || "-" }}</div>
      <div v-if="result.customer">Pelanggan: {{ result.customer.name }}</div>
      <div v-if="result.delivery_pickup === 'delivery'">
        Pengiriman: {{ result.delivery_pickup || "-" }}
      </div>
    </div>

    <div style="border-top: 1px solid #000; margin: 16px 0"></div>

    <!-- Product List -->
    <div class="product-list">
      <div v-for="(item, index) in result.items" :key="index" class="product-item">
        <div class="product-name">{{ item.name }} <span v-if="item.bundle">({{ item.bundle && item.bundle.name }})</span></div>
        <div class="product-details">
          <div class="product-details-type">
            <span>{{ item.qty.toString().replace(".", ",") }} {{ item.unit }}</span>
          </div>
          <div class="product-details-price" v-if="item.bundle == null">
            <span>{{ item.sub_price | formatInvoice }}</span>
          </div>
          <div class="product-details-price" v-if="item.bundle == null">
            <span v-if="item.discount_per_item > 1">-{{ item.discount_per_item | formatInvoice }}</span>
          </div>
          <div class="product-details-price" v-if="item.bundle == null">
            <span>{{ item.total | formatInvoice }}</span>
          </div>
        </div>
      </div>
    </div>

    <div style="border-top: 1px solid #000; margin: 16px 0"></div>

    <!-- Summary -->
    <div class="summary">
      <div class="summary-item">
        <span>Subtotal</span>
        <span>{{ result.subtotal | formatAmountAndroid }}</span>
      </div>

      <div class="summary-item" v-for="(payment, indexPayment) in result.payments" :key="indexPayment">
        <span>
          {{ paymentMethods(payment.payment_method) }}
          {{ payment.payment_method == "transfer" ? " ke " + payment.bank_account_payment.bank.name : "" }}
        </span>
        <span>
          {{ parseFloat(payment.amount) | formatAmountAndroid }}
        </span>
      </div>

      <div v-if="result.additional_fee_total > 0" class="summary-item">
        <span>Total Biaya Tambahan</span>
        <span>{{ result.additional_fee_total | formatAmountAndroid }}</span>
      </div>

      <!-- Split Payment -->
      <template v-if="result.payments && result.payments.length > 1">
        <div style="border-top: 1px solid #000; margin: 16px 0"></div>
        <div class="summary-header">Pembayaran Terbagi</div>
        <div v-for="(payment, indexPayment) in result.payments" :key="indexPayment" class="summary-item">
          <span>
            {{ paymentMethods(payment.payment_method) }}
            {{
              payment.payment_method == "transfer"
                ? " ke " + payment.bank_account_payment.bank.name
                : ""
            }}
          </span>
          <span>{{ payment.amount | formatAmountAndroid }}</span>
        </div>
        <div style="border-top: 1px solid #000; margin: 16px 0"></div>
        <div class="summary-item">
          <span>Total Pembayaran</span>
          <span>{{
            sumSplitPayment(result.payments) | formatAmountAndroid
          }}</span>
        </div>
      </template>

      <!-- Discount -->
      <template v-if="result.discount_value > 0">
        <div class="summary-item">
          <span>Diskon Tambahan</span>
          <span v-if="result.discount_type == 'fixed'">
            - {{ result.discount_value | formatAmountAndroid }}
          </span>
          <span v-if="result.discount_type == 'percent'">
            - {{ result.discount_value }}%
          </span>
        </div>
      </template>

      <div v-if="result.discount_total > 0" class="summary-item">
        <span>Total Diskon</span>
        <span>{{ result.discount_total | formatAmountAndroid }}</span>
      </div>

      <div class="summary-item total">
        <span>Grand Total</span>
        <span>{{ result.total | formatAmountAndroid }}</span>
      </div>

      <template v-if="
        result.payment_method !== 'piutang' &&
        result.piutang &&
        result.piutang.amount > 0
      ">
        <div class="summary-item">
          <span>Piutang</span>
          <span>{{ result.piutang.amount | formatAmountAndroid }}</span>
        </div>
      </template>

      <template v-if="result.payment_method !== 'piutang'">
        <div class="summary-item" v-if="result.payment_method === 'wallet'">
          <span>Sisa Deposit</span>
          <span>{{
            result.payment_customer &&
            result.payment_customer.wallet_balance | formatAmountAndroid
          }}</span>
        </div>
        <div class="summary-item" v-if="
          result.cash_change > 0 ||
          (result.returs_id && result.returs_id.length)
        ">
          <span>Kembali</span>
          <span>{{ result.cash_change | formatAmountAndroid }}</span>
        </div>
      </template>

      <div v-if="result.discount_total > 0" class="summary-item">
        <span>Anda Hemat</span>
        <span>{{ result.discount_total | formatAmountAndroid }}</span>
      </div>
    </div>

    <!-- Notes -->
    <div class="notes">
      <div class="note-header">Note:</div>
      <div v-if="result.display_vat === 1" class="note-item">
        *Harga barang sudah termasuk PPn 11%
      </div>
      <div class="note-item">{{ result.invoice_notes !== 'null' ? result.invoice_notes : '' }}</div>
    </div>

    <!-- Additional Info -->
    <div v-if="result.returs_id && result.returs_id.length > 0" class="additional-info">
      {{ result.returs_id.join(", ") }}
    </div>

    <div v-if="result.deleted_by" class="cancellation-info">
      Dibatalkan oleh : {{ result.deleted_by.name }} / {{ result.deleted_at }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ThermalPrintTemplate",
  props: {
    result: Object,
    merchant: Object,
    deliveryNumbers: Array,
  },
  data() {
    return {
      imageLogo: null
    }
  },
  result: {
    handler(value) {
      if (value) {
        const isValid = this.hasNoImageExtension(value?.branch?.photo_url)
        this.imageLogo = isValid ? value.branch?.photo_url : ``
      }
    },
    deep: true
  },
  methods: {
    hasNoImageExtension(url) {
      const validExtensions = /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i;
      return validExtensions.test(url);
    },
    formatInvoice(value) {
      // Implement your formatting logic here
      return value;
    },
    formatAmountAndroid(value) {
      // Implement your formatting logic here
      return value;
    },
    paymentMethods(method) {
      const paymentMapping = {
        cash: "Tunai",
        card: "Debit/Kredit",
        transfer: "Transfer",
        giro: "Giro",
        wallet: "Deposit",
        piutang: "Piutang",
      };
      return paymentMapping[method] || "-";
    },
    sumSplitPayment(payments) {
      return payments.reduce(
        (total, payment) => total + parseFloat(payment.amount),
        0
      );
    },
  },
};
</script>

<!-- <style scoped>
  .thermal-print {
    font-family: 'Courier', monospace;
    font-size: 12px;
    line-height: 1.2;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 10px 0;
  }

  .logo {
    display: block;
    margin: 0 auto 10px;
    max-width: 60px !important;
    height: auto;
  }
  
  .header, .invoice-details, .product-list, .summary, .notes {
    margin-bottom: 10px;
  }
  
  .branch-name {
    font-size: 42px !important;
    font-weight: bold;
  }
  
  .divider {
    border-top: 1px dashed #000;
    margin: 10px 0;
  }
  
  .product-item {
    margin-bottom: 5px;
  }
  
  .product-name {
    font-weight: bold;
  }
  
  .product-details {
    display: flex;
    justify-content: space-between;
  }
  
  .summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  }
  
  .summary-header {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .total {
    font-weight: bold;
    font-size: 42px !important;
    margin-top: 5px;
  }
  
  .note-header {
    font-weight: bold;
    margin-bottom: 2px;
  }
  
  .note-item {
    font-style: italic;
  }
  
  .additional-info, .cancellation-info {
    margin-top: 10px;
    font-style: italic;
  }
  
  .cancellation-info {
    color: #ff0000;
  }
  </style> -->
